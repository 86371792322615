import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
        --background: #ffffff;
        --blue: #537ca7;
        --blue-light: #5A95D2;
        --text-title: #000000;
        --text-body: #4a4a4a;
        --text-body-light: #FFFFFF;
    }

    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box
    }

    html {
        // Using % because user font config can be diferent
        @media (max-width: 1080px){
            font-size: 93.75%; // 15px
        }

        @media (max-widht: 720px) {
            font-size: 87.5%; //14px
        }
        height: 100%;
        overflow: scroll;
        overflow-x: hidden;
  }

  body {
      background: var(--background);
      -webkit-font-smoothing: antialised;
  }

  body, input, textarea {
      font-family: 'Fira code', sans-serif;
      font-weight: 400;
      color: var(--text-body);
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-family: 'Fira code', sans-serif;
    font-weight: 600;
    color: var(--text-title);
  }

  // Button Click
  button {
    cursor: pointer;
  }

  // Styles to disabled components
  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;