import {
    Container,
    Card,
} from './styles';

import {
    FiLink
} from 'react-icons/fi';

interface projectType {
    name: string;
    year: number;
    goal: string;
    description: string;
    tags: Array<string>;
    href: string;
};

interface Props {
    projects?: Array<projectType>;
}

// interface projectType extends Array<projectType>{}

export const ProjectsGrid = ({projects} : Props) => {

    return (
        <Container>
            {projects && projects.map((project) => (
                <Card>
                    <div className="header">
                        { project.year }
                        <a href={project.href}>
                            <FiLink />
                        </a>
                    </div>
                    <div className="title">{project.name}</div>
                    <div className="subtitle">{project.goal}</div>
                    <div className="description">{project.description}</div>
                    <div className="tagList">
                        { project.tags.map((tag) => (
                                <div className="tag">{tag}</div>
                        ))}
                    </div>
                </Card>
            ))}
        </Container>
    )
}