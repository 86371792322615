import { useEffect } from 'react';
import ReactGA from 'react-ga';

import { LandingPage } from './pages/landingPage';
import { GlobalStyle } from './styles/global';

ReactGA.initialize("UA-214295198-1");
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <>
      <GlobalStyle/>
      <LandingPage/>
    </>
  );
}

export default App;
