import { useState} from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import Computer from "../../assets/computer.svg"

import { 
    Container, 
    Content, 
    Title, 
    ButtonArea, 
    HamburguerArea,
    HamburguerOptions,
    HamburguerDiv
} from './styles';

export const Header = () => {

    const [openHamburguer, setOpenHamburguer] = useState(false);

    // universal smooth scroll
    smoothscroll.polyfill();

    const scroll = (id : string) => {
        var x = document.getElementById(id);
        if (openHamburguer){
            setOpenHamburguer(false);
        }
        x && window.scrollTo({
          top: x.offsetTop,
          behavior:"smooth"
        });
    };

    return (
    <Container>
        <Content>
            <Title>
                <img src={Computer} alt="teste" height="20px"/>
                <h3>Pablo</h3>
            </Title>
            <ButtonArea>
                <button onClick={() => scroll("about")}>Sobre mim</button>
                <button onClick={() => scroll("projects")}>Projetos</button>
                <button onClick={() => scroll("skills")}>Habilidades</button>
                <button onClick={() => scroll("experiences")}>Experiência e habilidades</button>
                <button onClick={() => scroll("social")}>Social</button>
            </ButtonArea>
            <HamburguerArea>
                <HamburguerDiv onClick={() => setOpenHamburguer(prevValue => !prevValue)}  >
                    <div className="burguer 0" ></div>
                    <div className="burguer 1"></div>
                </HamburguerDiv>
                <HamburguerOptions openHamburguer={openHamburguer} >
                    <button onClick={() => scroll("about")}>Sobre mim</button>
                    <button onClick={() => scroll("projects")}>Projetos</button>
                    <button onClick={() => scroll("skills")}>Habilidades</button>
                    <button onClick={() => scroll("experiences")}>Experiência e habilidades</button>
                    <button onClick={() => scroll("social")}>Social</button>
                </HamburguerOptions>
            </HamburguerArea>
        </Content>
    </Container>
    );
};