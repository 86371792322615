import styled from 'styled-components';

type ContainerProps = {
    titleColor?: string;
};

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
`;

export const Content = styled.div`
    #btn {
        z-index: 3;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-top: 4rem;

    @media (max-width: 800px){
        margin-top: 0;
    }

    .waveReverse {
        width: 100%;
        /* max-width: 1080px; */
    }

    .gradient {
        display: flex;
        height: 120px;
        width: 100%;
        /* max-width: 1080px; */
        background-image: linear-gradient(
            rgba(209, 221, 232, 1) 0%,
            rgba(209, 221, 232, 0.3) 50%,
            rgba(209, 221, 232, 0.15) 65%,
            rgba(209, 221, 232, 0.075) 75.5%,
            rgba(209, 221, 232, 0.037) 82.85%,
            rgba(209, 221, 232, 0.019) 88%,
            rgba(209, 221, 232, 0) 100%
        );
        color: blue;
    }

    .codeThinking {
        display: flex;
        justify-content: right;
        width: 100%;
        max-width: 1080px;
    }
    .thinking {
        margin-top: 4rem;
        width: 80%;
        max-width: 1080px;
    }
`;

export const Title = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    /* max-width: 1080px; */

    font-weight: 800;
    padding: 3rem 0rem;

    background-color: ${({titleColor}) => titleColor};

    position: relative;

    .titleBackground {
        display: flex;
        align-items: center;
        justify-content: center;
        
        max-width: 800px;

        font-family: 'Montserrat';
        font-size: 30px;
        text-decoration: underline;
        line-height: .93;
        color: #002d5d;
        text-align: center;

        z-index: 2;

    }

    img {
        @media (max-width: 600px){
            display: none;
        }
        position: absolute;
        right: 4rem;
    }
`;

export const MainCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 3rem 1rem;

    width: 100%;
    max-width: 800px;


    .leftContent{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 4rem;

        .text {
            font-weight: 300;
            font-size: 15px;
            color: black;
            z-index: 2;
        }

        .title {
            font-weight: 700;
            font-size: 25px;
            color: #002d5d;
            z-index: 2;
        }

        .whatsappButton {
            margin-top: 2rem;
            border: none;
            background-color: rgba(255, 255, 255,0.8);
            width: 200px;
            height: 2rem;
            border-radius: 5px;
            z-index: 2;

            font-family: 'Fira Code';
            color: #002d5d;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .contact {
                margin: 0rem .5rem;
                text-decoration: none;
            }

            a {
                color: inherit;
            }
        }

    }

    .rightContent{
        display: flex;

        @media (max-width: 400px){
            display: none;
        }

        flex-direction: column;
        align-items: center;
        width: 100%;
        
        img {
            max-width: 768px;
            z-index: 2;
        }
    }
`;

export const MainCardbackground = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    /* max-width: 1080px; */

    position: relative;

    .wave {
        position: absolute;
        bottom: 0;
        z-index: 0;
    }
`;


export const BiographyCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #d1dde8;

    padding: 4rem 1.5rem;

    width: 100%;
    max-width: 900px;

    .rightContent{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 0rem 0rem;

        .text {
            font-weight: 300;
            color: black;
        }

        .title {
            font-weight: 700;
            font-size: 25px;
            color: #002d5d;
            
            margin: 1rem 0rem;
        }

        .button {
            margin-top: 2rem;
            border: none;
            background-color: rgba(255, 255, 255, .6);
            width: 120px;
            height: 2rem;
            border-radius: 5px;

            font-family: 'Fira Code';
            color: #002d5d;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .contact {
                margin: 0rem .5rem;
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            flex-wrap: wrap;

            button > a {
                text-decoration: none;
            }

            button {
                margin: 2rem 1rem 0rem 1rem;
            }

            a {
                color: inherit;
            }
        }

    }

    .leftContent{
        display: flex;

        @media (max-width: 800px){
            display: none;
        }

        flex-direction: column;
        align-items: center;
        width: 100%;
        
        img {
            max-width: 250px;
            border-radius: 500px;
        }
    }
`;

export const BiographyCardBackground = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d1dde8;
    width: 100%;
    /* max-width: 1080px; */
`;

export const SkillsCardBackground = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d1dde8;
    width: 100%;
    /* max-width: 1080px; */
`;

export const SkillsCard = styled.div`
    display: flex;
    flex-direction: row;
    @media(max-width: 800px){
        flex-direction: column;
    }   
    align-items: center;
    justify-content: center;

    padding: 3rem 1rem;

    width: 100%;
    max-width: 1080px;

    .leftContent{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 4rem;

        text-align: justify;

        .text {
            font-weight: 300;
            font-size: 15px;
            color: black;
            z-index: 2;
            margin-top: 1rem;
        }

        .title {
            font-weight: 700;
            font-size: 25px;
            color: #002d5d;
            z-index: 2;
        }
    }

    .rightContent{
        display: flex;

        flex-direction: row;

        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        
        img {
            max-width: 768px;
            z-index: 2;
        }

        .badge {
            background-color: #002d5d;
            color: white;
            padding: .5rem .5rem;
            margin: .4rem;
            border-radius: 10px;
        }
    }
`;  

export const ExperienceCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* max-width: 1080px; */

    margin: 2rem 0rem;

    .leftContent {
        width: 100%;
        height: unset;
        max-height: 300px;
        display: flex;
        @media(max-width: 600px){
            display: none;
        }  
        justify-content: center;
    }

    .rightContent {
        display: flex;
        flex-direction: column; 
        /* flex-wrap: wrap; */
        align-items: flex-start;
        justify-content: center;

        width: 100%;
        height: unset;
        background-color: #d1dde8;
        border-radius: 10px 0px 0px 10px;

        .card {
            max-width: 400px;
            min-width: 200px;
            padding: 2rem;
            .title {    
                font-weight: 800;
            }
            .text {
                margin-top: 1rem;
            }
        }
    }
`;

export const ExperienceCard2 = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    /* max-width: 1080px; */

    margin: 2rem 0rem;

    .rightContent {
        width: 100%;
        
        display: flex;
        @media(max-width: 600px){
            display: none;
        }  
        justify-content: center;
        align-items: flex-end;

        img {
            max-height: 300px;
        }
    }

    .leftContent {
        display: flex;
        flex-direction: column; 
        /* flex-wrap: wrap; */
        align-items: flex-end;
        justify-content: center;

        width: 100%;
        height: unset;
        background-color: #d1dde8;
        border-radius: 0px 10px 10px 0px;

        .card {
            max-width: 400px;
            min-width: 200px;
            padding: 2rem;
            .title {    
                font-weight: 800;
            }
            .text {
                margin-top: 1rem;
            }
        }
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 90%;
    max-width: 1080px;

    .title {
        font-weight: 800;
        color: #002d5d;
    }

    .text {

    }

    .social {
        display: flex;
        flex-direction: row;
        color: #002d5d;
        margin: 2rem 0rem;

        .icons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0rem .4rem;

            a {
                color: inherit;
            }

            .icon {
                margin: 0rem .4rem;
            }
        }
    }
`;