import { Header } from '../components/Header/index';
import { ProjectsGrid } from '../components/ProjectsGrid';
import { FiGithub, FiInstagram, FiLinkedin, FiSmartphone } from "react-icons/fi";
import {
    Container,
    Content,
    Title,
    MainCard,
    MainCardbackground,
    BiographyCard,
    BiographyCardBackground,
    SkillsCardBackground,
    SkillsCard,
    ExperienceCard,
    ExperienceCard2,
    Footer
} from './styles';

import devFocus from '../assets/dev_focus.svg';
import profile from '../assets/profile.jpg';
import wave from '../assets/wave.svg';
import wave1 from '../assets/wave1.svg';
import detail from '../assets/square_details.svg';
import think from '../assets/code_thinking.svg';
import enviroment from '../assets/digital_enviroment.svg'
import person from '../assets/person.svg';

interface projectType {
    name: string;
    year: number;
    goal: string;
    description: string;
    tags: Array<string>;
    href: string;
}

export const LandingPage = () => {

    const personalProjects : Array<projectType> = [
        {
            name: "Cosseno.com",
            year: 2021,
            goal: "A plataforma oficial do estudo em alto nível",
            description: "Em 2021 foi lançado o cosseno.com, uma excelente plataforma para estudos em alto nível. Sou fundador e atuo como desevolvedor na plataforma, que todo dia me agrega bastante conhecimento na área de empreendedorismo e desenvolvimento.",
            tags: ["lançado", "web"],
            href: "https://cosseno.com/"
        },
        {
            name: "Programação competitiva",
            year: 2019,
            goal: "Estudos em competições de programação",
            description: "Há algum tempo venho me dedicando no estudo de algoritmos, estruturas de dados e temas diversos para competições de programação.",
            tags: ["estudos", "competição"],
            href: "https://codeforces.com/profile/pablo_aaaa"
        },
        {
            name: "Portfólio",
            year: 2021,
            goal: "Construção desse website que você está vendo agora.",
            description: "Com objetivo de construir algo mais profissional e para que as pessoas vejam meu trabalho, criei este site.",
            tags: ["work", "competição"],
            href: "https://araujopablo.com"
        }
    ];

    const skills = ["HTML/CSS", "React js", "Vue js", "Node js", "Firebase", "Git", "C++", "Prog. competitiva", "Python", "Javascript", "Machine Learning", "Pandas", "Scikit Learn"]

    return (
        <Container>
            <Header/>
            <Content>
                <MainCardbackground>
                    <MainCard>
                        <div className="leftContent">
                            <span className="title">Pablo Arruda</span>
                            <span className="text">Desenvolvedor criando novas ideias.</span>
                            <button className="whatsappButton">
                                <FiSmartphone/>
                                <a href="https://wa.me/+5561996142188" target="_blank" rel="noreferrer" className="contact">Entre em contato</a>
                            </button>
                        </div>
                        <div className="rightContent">
                            <img src={devFocus} alt="dev" width="80%"/>
                        </div>
                    </MainCard>
                    <img src={wave} className="wave" alt="" width="100%"/>
                </MainCardbackground>
                <Title id="about" titleColor={"#d1dde8"}> 
                    <div className="titleBackground"> Um pouco sobre mim  </div>
                </Title>
                <BiographyCardBackground>
                    <BiographyCard>
                        <div className="leftContent">
                            <img src={profile} alt="dev" width="80%"/>
                        </div>
                        <div className="rightContent">
                            <span className="title">PABLO ARRUDA ARAUJO</span>
                            <span className="text"> 
                                Espírito empreendedor, 20 anos, apaixonado por conhecimentos e contato com novas pessoas, estudante de Engenharia da computação e desenvolvedor. Fundador e Dev da Cosseno (cosseno.com). Experiências diversas em programação e design. Busco sempre agregar o máximo que posso no ambiente em que estou com as ferramentas que possuo. Sempre atrás de novos conhecimento, estudando minhas áreas de interesse visando atingir a excelência e impactar positivamente as pessoas utilizando essas ferramentas. <br/> <br/>
                                No ensino médio tive muito contato com olimpíadas científicas, fui medalhista nacional e regional em alguma delas, isso me trouxe bagagens e experiências muito importantes, hoje aplico diariamente esses conhecimentos no mundo do empreendedorismo e também na programação competitiva.
                            </span>
                            <div className="buttons">
                                <button className="button">
                                    <FiLinkedin/>
                                    <a href="https://www.linkedin.com/in/pabloarruda/" target="_blank" rel="noreferrer" className="contact">linkedin</a>
                                </button>
                                <button className="button">
                                    <FiGithub/>
                                    <a href="https://github.com/pablo-aa" target="_blank" rel="noreferrer" className="contact">Github</a>
                                </button>
                            </div>
                        </div>
                    </BiographyCard>
                </BiographyCardBackground>
                <div className="gradient"></div>
                <Title id="projects" titleColor={"#ffffff"}>
                    <img src={detail} alt="" width="100px"/>
                    <div className="titleBackground"> Alguns dos meus projetos </div>
                </Title>
                <ProjectsGrid projects={ personalProjects }/>
                <div className="codeThinking">
                    <img className="thinking" src={think} alt="thinking"/>
                </div>

                <SkillsCardBackground id="skills">
                    <SkillsCard>
                        <div className="leftContent">
                            <span className="title">Habilidades</span>
                            <span className="text">
                                Desde 2019 venho estudando intensivamente o mundo da programação. Para uma mente empreendedora o mundo da tecnologia possibilita ter em mãos uma ferramenta mágica. Ao longo desse tempo pude me desenvolver em diversas áreas como: <br/> <br/>
                                - C/C++ (Foco em programação competitiva • algoritmos e estrutura de dados). <br/> <br/>
                                - React js e Vue js para desenvolvimento web. Constante uso de HTML e CSS <br/> <br/>
                                - Node js para backend. Experiência com Firebase. <br/> <br/>
                                - Python (Algoritmos, Inteligência artificial com Scikit learn, análise de dados com Pandas etc.) <br/> <br/>
                            </span>
                        </div>
                        <div className="rightContent">
                            {skills.map((skill)=> (
                                <div className="badge">{skill}</div>
                            ))}
                        </div>
                    </SkillsCard>
                </SkillsCardBackground>
                <img src={wave1} className="waveReverse" alt="" width="100%"/>
                <Title id="experiences" titleColor={"#fff"}> 
                    <div className="titleBackground"> Experiências e formação  </div>
                </Title>
                <ExperienceCard>
                    <div className="leftContent">
                        <img src={enviroment} alt="enviroment" width="100%"/>
                    </div>
                    <div className="rightContent">
                        <div className="card">
                            <div className="title">Eng. da computação | UnB</div>
                            <div className="text">Desde 2019 estudo Engenharia da computação na UnB. O espaço da universidade é excelente para ampliar o networking e ganhar conhecimento em áreas como programação competitiva.</div>
                        </div>
                        <div className="card">
                            <div className="title">Gostack | Rocketseat</div>
                            <div className="text"> Bootcamp completo para desevolvimento nas stacks de front-end (React js / React Native) e backend (Node js). Foram 150h horas de imersão distribuídas em 4 meses, grande parte do meu conhecimento em desenvolvimento de aplicações vem deste curso.</div>
                        </div>
                    </div>
                </ExperienceCard>
                <ExperienceCard2>
                    <div className="leftContent">
                        <div className="card">
                            <div className="title">Founder & Dev | Cosseno</div>
                            <div className="text"> Em 2021 foi lançado o cosseno.com, uma excelente plataforma para estudos em alto nível. Sou fundador e atuo como desevolvedor na plataforma, que todo dia me agrega bastante conhecimento na área de empreendedorismo e desenvolvimento.</div>
                        </div>
                        <div className="card">
                            <div className="title">Pesquisador Bolsista | UnB</div>
                            <div className="text"> Em 2020 tive a oportunidade de iniciar uma pesquisa que tinha como objetivo o reconhecimento automatizado de câncer de pele através da tecnologia de Deep Learning. Realizei apenas 7 meses de pesquisa mas adquiri a maioria dos meus conhecimentos na área de inteligência artificial.</div>
                        </div>
                        <div className="card">
                            <div className="title">Coordenador de olimpíadas | Olimpo</div>
                            <div className="text"> Sou coordenador de olimpíadas no colégio Olimpo, devido ao meu grande envolvimento com olimpíadas no ensino médio, resolvi retribuir essa experiência orientando os estudantes na escola. Ótima oportunidade para ter contato com os alunos e estimular o conhecimento de olimpíadas no ambiente escolar.</div>
                        </div>
                    </div>
                    <div className="rightContent">
                        <img src={person} alt="enviroment" width="100%"/>
                    </div>
                </ExperienceCard2>
                <Footer id="social">
                    <div className="title">MADE BY PABLO</div>
                    <div className="social">
                        <div className="text">Hi</div>
                        <div className="icons">
                            <a href="https://www.instagram.com/pablo_aa/" target="_blank" rel="noreferrer" className="icon"><FiInstagram /></a>
                            <a href="https://github.com/pablo-aa" target="_blank" rel="noreferrer" className="icon"><FiGithub /></a>
                            <a href="https://www.linkedin.com/in/pabloarruda/" target="_blank" rel="noreferrer" className="icon"><FiLinkedin /></a>
                        </div>
                    </div>
                </Footer>
            </Content>
        </Container>       
    );
};
