import styled from 'styled-components';

type ContainerProps = {
    openHamburguer?: boolean;
};

export const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;

    width: 100%;

    justify-content: center;
    z-index: 99;

`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    background-color: #ffffff;
    
    border-radius: 100px;
    
    width: 80%;
    max-width: 980px;
    margin: 1rem auto;
    padding: .3rem 1rem;

    font-weight: 600;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Fira code';

    img {
        margin: 0rem 1rem;
    }
`;

export const ButtonArea = styled.div`
    display: flex;

    @media (max-width: 1000px) {
        display: none;
    }

    position: relative;
    button {
        background: none;
        font-family: 'Fira code', sans-serif;
        font-weight: 600;
        color: var(--text-title);
        border: 0;
        margin: 0rem 1rem;

        &:last-child{
            padding: .0rem 0rem;
            border-radius: 20px;
            color: var(--blue);
        }
    }
`;


export const SocialOptions = styled.div`
    display: flex;
    flex-direction: column;
    
    position: absolute;
    
    right: 0rem;
    top: 3rem;
    
    border-radius: 10px;
    background-color: #FFFFFF;
    
    button {
        padding: .5rem 0rem !important;
        background-color: #FFFFFF !important;
        color: var(--text-title) !important;
    }
`;

export const HamburguerArea = styled.div`
    display: none;

    @media (max-width: 1000px) {
        display: flex;
        align-items: center;
    }

    justify-content: space-around;

    width: 2rem;
    height: 1rem;

    .burguer {
        z-index: 2;
        width: 2rem;
        height: .2rem;
        border-radius: 50px;
        background-color: #1a1a1a;
    }
`;

export const HamburguerOptions = styled.div<ContainerProps>`

    display: flex;
    transform: ${({openHamburguer}) => openHamburguer ? 'translateX(0%)' : 'translateX(100%)'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0,45,93,1);
    height: 100vh;
    width: 100%;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s linear;

    button {
        background: none;
        font-family: 'Fira code', sans-serif;
        font-weight: 600;
        color: white;
        border: 0;
        margin: 2rem 0rem;
    }
`;

export const HamburguerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: .6rem;
    z-index: 2;

    cursor: pointer;
`;
