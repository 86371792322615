import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1080px;
    
    flex-wrap: wrap;
`;  

export const Card = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: space-between;

    width: 300px;

    height: 250px;
    background-color: #ffffff;
    margin: 2rem 1rem;

    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 1rem;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #002d5d;
        font-weight: 600;
        font-size: .8rem;
    }

    .title {
        color: black;
        font-weight: 900;
    }

    .subtitle {
        width: 70%;
        color: black;
        font-weight: 600;
        font-size: .7rem;
        margin-top: -1rem;
    }

    .description {
        color: black;
        font-weight: 400;
        font-size: .7rem;
    }

    .tagList {
        display: flex;
        flex-direction: row;
    }

    .tag {
        color: white;
        font-size: .7rem;
        background-color: #002d5d;
        border-radius: 10px;
        padding: .2rem .4rem;
        margin-right: .5rem;
    }
`;